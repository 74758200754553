import AuthClient from "./AuthClient";
import queryString from "query-string";

const TripEventsApi = {
  getTripEvent: async (cancelToken, scheduleId, returnBlank = false) => {
    const response = await AuthClient.get(
      `TripEvents/${scheduleId}?returnBlank=${returnBlank}`,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  updateTripEvent: async (cancelToken, trip) => {
    const response = await AuthClient.post("TripEvents", trip, { cancelToken });
    return response.data.payload || {};
  },
  getTripEvents: async (cancelToken, scheduleId) => {
    const response = await AuthClient.get("TripEvents/basic", {
      params: { scheduleId },
      cancelToken,
    });
    return response.data.payload || [];
  },
  getTripAttendees: async (
    cancelToken,
    tripEventId,
    page,
    results,
    sortBy,
    filters,
    exportToCSV
  ) => {
    const params = {
      page,
      results,
      sortByOption: sortBy,
      campusId: filters.campusId,
      shliachId: filters.shliachId,
      statuses: filters.statuses,
      text: filters.keyword,
      tourScheduleIds: filters.tourScheduleIds,
      trackIds: filters.trackIds,
      attendeeTypes: filters.attendeeTypes,
      isExport: exportToCSV,
    };

    const response = await AuthClient.get(
      `TripEvents/${tripEventId}/attendees?${queryString.stringify(params)}`,
      { cancelToken }
    );
    return response?.data?.payload || {};
  },
  getTripRegistrations: async (
    cancelToken,
    tripEventId,
    page,
    results,
    sortBy,
    filters,
    shouldShowMore,
    exportType
  ) => {
    const params = {
      attendeeTypes: filters.attendeeTypes,
      campusId: filters.campusId,
      exportType,
      gender: filters.gender,
      page,
      results,
      shliachId: filters.shliachId,
      shouldShowMore,
      sortByOption: sortBy,
      statuses: filters.statuses,
      text: filters.keyword,
      tourScheduleIds: filters.tourScheduleIds,
      trackIds: filters.trackIds,
      tripEventId,
      wasDepositRefunded: filters.wasDepositRefunded,
      wasParentLetterSubmitted: filters.wasParentLetterSubmitted,
      wasSurveySubmitted: filters.wasSurveySubmitted,
      workflowSteps: filters.workflowSteps,
    };

    const response = await AuthClient.get(
      `TripRegistrations/byEvent?${queryString.stringify(params)}`,
      { cancelToken }
    );
    return {
      registrations: response.data.payload.results || [],
      totalRegistrations: response.data.payload.numberOfRows || 0,
    };
  },
  getTripEnrollments: async (
    cancelToken,
    tripEventId,
    page,
    results,
    sortBy,
    filters,
    exportType
  ) => {
    const params = {
      exportType,
      page,
      results,
      sortByOption: sortBy,
      status: filters.enrolled ? "Enrolled" : "UnEnrolled",
      text: filters.keyword,
      tripEventId,
    };

    const response = await AuthClient.get(
      `TripEnrollments?${queryString.stringify(params)}`,
      { cancelToken }
    );
    return {
      enrollments: response.data.payload.results || [],
      totalEnrollments: response.data.payload.numberOfRows || 0,
    };
  },
  getAllTripEnrollments: async (cancelToken, tripEventId) => {
    const response = await AuthClient.get(
      `TripEnrollments/basic?tripEventId=${tripEventId}`,
      { cancelToken }
    );
    return response.data.payload || [];
  },
  getTripMetrics: async (cancelToken, tripEventId) => {
    const response = await AuthClient.get(`TripEvents/${tripEventId}/Metrics`, {
      cancelToken,
    });
    return response.data.payload || {};
  },
  getTripReport: async (cancelToken, tripEventId, reportType, text) => {
    const params = {
      text,
      tripReports: reportType,
    };
    const response = await AuthClient.get(
      `TripEvents/${tripEventId}/reports?${queryString.stringify(params)}`,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  getTripEnrollment: async (cancelToken, enrollmentId) => {
    const response = await AuthClient.get(`TripEnrollments/${enrollmentId}`, {
      cancelToken,
    });
    return response.data.payload || {};
  },
  deleteTripEnrollment: async (cancelToken, enrollmentId) => {
    const response = await AuthClient.post(
      `TripEnrollments/Deactivate/${enrollmentId}`,
      null,
      { cancelToken }
    );
    return response;
  },
  createTripEnrollments: async (cancelToken, tripEventId, chabadHouseIds) => {
    const response = await AuthClient.post(
      "TripEnrollments/bulk",
      { tripEventId, chabadHouseIds },
      { cancelToken }
    );
    return response;
  },
  getTripEnrollmentByStudent: async (
    cancelToken,
    tripEventId,
    registrationId
  ) => {
    const response = await AuthClient.get(
      `TripEnrollments/student?tripEventId=${tripEventId}&registrationId=${registrationId}`,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  getTripAttendeeDetails: async (
    cancelToken,
    tripEventId,
    studentRegistrationIdsToInclude,
    enrollmentAttendeeRegistrationIdsToInclude,
    studentRegistrationIdsToExclude,
    enrollmentAttendeeRegistrationIdsToExclude,
    filters
  ) => {
    const params = {
      studentRegistrationIdsToInclude,
      enrollmentAttendeeRegistrationIdsToInclude,
      studentRegistrationIdsToExclude,
      enrollmentAttendeeRegistrationIdsToExclude,
      tripEventId,
      ...(filters
        ? {
            campusId: filters.campusId,
            shliachId: filters.shliachId,
            statuses: filters.statuses,
            text: filters.keyword,
            tourScheduleIds: filters.tourScheduleIds,
            trackIds: filters.trackIds,
            attendeeTypes: filters.attendeeTypes,
          }
        : {}),
    };

    const response = await AuthClient.get(
      `TripEvents/${tripEventId}/attendeeDetails?${queryString.stringify(
        params
      )}`,
      { cancelToken }
    );
    return response.data.payload || [];
  },

  getTripRegistration: async (cancelToken, registrationId) => {
    const response = await AuthClient.get(
      `TripRegistrations/${registrationId}`,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  getTripRegistrationAvailableRefunds: async (cancelToken, registrationId) => {
    const response = await AuthClient.get(
      `TripRegistrations/${registrationId}/availableRefunds`,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  updateTripRegistration: async (cancelToken, tripRegistration) => {
    const response = await AuthClient.post(
      "TripRegistrations",
      tripRegistration,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  updateTripRegistrationStatus: async (
    cancelToken,
    registrationId,
    status,
    skipStudentRejectionEmail = false,
    refundType
  ) => {
    const response = await AuthClient.post(
      "TripRegistrations/status",
      {
        registrationId,
        status,
        skipStudentRejectionEmail,
        refundType,
      },
      { cancelToken }
    );
    return response.data.payload || {};
  },
  updateTripRegistrationWorkflowStep: async (
    cancelToken,
    registrationId,
    workflowStep
  ) => {
    const response = await AuthClient.post(
      "TripRegistrations/workflowStep",
      {
        registrationId,
        workflowStep,
      },
      { cancelToken }
    );
    return response.data.payload || {};
  },
  bulkUpdateTripRegistrationStatus: async (
    cancelToken,
    registrationIds,
    status,
    skipStudentRejectionEmail = false,
    doRefund = false
  ) => {
    const response = await AuthClient.post(
      "TripRegistrations/status/bulk",
      {
        registrationIds,
        status,
        skipStudentRejectionEmail,
        doRefund,
      },
      { cancelToken }
    );
    return response;
  },
  bulkUpdateTripRegistrationWorkflowStep: async (
    cancelToken,
    registrationIds,
    workflowStep
  ) => {
    const response = await AuthClient.post(
      "TripRegistrations/workflowStep/bulk",
      {
        registrationIds,
        workflowStep,
      },
      { cancelToken }
    );
    return response;
  },
  issuePartialRefund: async (
    cancelToken,
    tripRegistrationId,
    { cociRefundAmount, shliachRefundAmount, depositRefundAmount }
  ) => {
    const response = await AuthClient.post(
      "TripRegistrations/partialRefund",
      {
        tripRegistrationId,
        cociRefundAmount,
        shliachRefundAmount,
        depositRefundAmount,
      },
      { cancelToken }
    );
    return response.data.payload || {};
  },
  checkinTripAttendees: async (
    cancelToken,
    tripEventId,
    studentRegistrationIdsToInclude,
    enrollmentAttendeeRegistrationIdsToInclude,
    studentRegistrationIdsToExclude,
    enrollmentAttendeeRegistrationIdsToExclude,
    filters
  ) => {
    const params = {
      studentRegistrationIdsToInclude,
      enrollmentAttendeeRegistrationIdsToInclude,
      studentRegistrationIdsToExclude,
      enrollmentAttendeeRegistrationIdsToExclude,
      tripEventId,
      ...(filters
        ? {
            campusId: filters.campusId,
            shliachId: filters.shliachId,
            statuses: filters.statuses,
            text: filters.keyword,
            tourScheduleIds: filters.tourScheduleIds,
            trackIds: filters.trackIds,
            attendeeTypes: filters.attendeeTypes,
          }
        : {}),
    };

    const response = await AuthClient.post(
      `TripEvents/${tripEventId}/checkin`,
      params,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  deleteTripRegistration: async (cancelToken, tripRegistrationId) => {
    const response = await AuthClient.delete(
      `TripRegistrations/${tripRegistrationId}`,
      null,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  getTripRegistrationsOverTimeComparison: async (
    cancelToken,
    tripID,
    includeAllEvents
  ) => {
    const params = {
      includeAllEvents,
      eventID: tripID,
    };

    const response = await AuthClient.get(
      `tripEvents/registrationYearToYearComparison?${queryString.stringify(
        params
      )}`,
      { cancelToken }
    );
    return response?.data?.payload || [];
  },
  getTripRegistrationNotes: async (cancelToken, registrationId) => {
    const response = await AuthClient.get(
      `TripRegistrations/${registrationId}/notes`,
      { cancelToken }
    );
    return response.data.payload || [];
  },
  submitTripRegistrationNote: async (cancelToken, registrationId, note) => {
    const response = await AuthClient.post(
      `TripRegistrations/${registrationId}/notes`,
      note,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  deleteTripRegistrationNote: async (cancelToken, registrationId, noteId) => {
    const response = await AuthClient.delete(
      `TripRegistrations/${registrationId}/notes/${noteId}`,
      null,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  getTripRegistrationRecommendation: async (cancelToken, registrationId) => {
    const response = await AuthClient.get(
      `TripRegistrations/${registrationId}/recommendation`,
      { cancelToken }
    );
    return response.data.payload || {};
  },
};

export default TripEventsApi;
