import React from "react";
import {
  formatFullName,
  formatNumber,
  pluralizeText,
} from "../../../../lib/utils";

function CheckInStudentDetails(props) {
  const { studentDetails, tracks, tours } = props;

  const singleSelectedStudent =
    studentDetails.length === 1 ? studentDetails[0] : undefined;

  const trackCountsNote = () => {
    if (singleSelectedStudent) {
      const track = tracks.find(
        (t) => t.id.toString() === studentDetails[0].trackID.toString()
      );
      return track
        ? `This student is attending the ${track.trackName} track.`
        : null;
    }

    const tracksRegistrationCounts = {};
    studentDetails.forEach(
      (s) =>
        (tracksRegistrationCounts[s.trackID] =
          (tracksRegistrationCounts[s.trackID] || 0) + 1)
    );

    return (
      <div>
        <p>You are checking in students attending the following tracks:</p>
        <div className="accent-text">
          {Object.keys(tracksRegistrationCounts).map((trackID) => {
            const track =
              tracks.find((t) => t.id.toString() === trackID.toString()) || {};
            return (
              <div className="two-column-grid" key={trackID}>
                <p>{track.trackName || "Untitled Track"}</p>
                <p>
                  {formatNumber(tracksRegistrationCounts[trackID])}{" "}
                  {pluralizeText("student", tracksRegistrationCounts[trackID])}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const restrictedTrackNote = () => {
    const restrictedTracks = tracks.filter((t) => t.ageMinimum === 21);
    const restrictedTrackIds = restrictedTracks.map((t) => t.id);
    const studentsRegisteredInRestrictedTracks = studentDetails.filter(
      (s) => restrictedTrackIds.indexOf(s.tripTrackID) >= 0
    );

    if (studentsRegisteredInRestrictedTracks.length) {
      if (singleSelectedStudent) {
        return `Please make sure to check this student's ID to ensure that ${
          singleSelectedStudent.gender === "Male" ? "he" : "she"
        } is over 21.`;
      } else {
        return (
          <div>
            <p>
              Please check the IDs of the following student(s) to ensure that
              they are over 21:
            </p>
            <div className="accent-text two-column-grid">
              {studentsRegisteredInRestrictedTracks.map(
                ({ id, studentFirstName, studentLastName, trackName }) => (
                  <div className="two-column-grid" key={id}>
                    <p className="mr-6">
                      {formatFullName(studentFirstName, studentLastName)}
                    </p>
                    <p className="italic-text">{trackName}</p>
                  </div>
                )
              )}
            </div>
          </div>
        );
      }
    }

    return null;
  };

  const toursList = () => {
    const attendeeTours = studentDetails.filter(
      (a) => a.tourScheduleIDs && a.tourScheduleIDs.length
    );
    if (!attendeeTours || !attendeeTours.length) {
      return null;
    }

    const tourSchedules = [];
    tours.forEach(({ schedules, ...tour }) => {
      if (schedules && schedules.length) {
        tourSchedules.push(
          ...schedules
            .filter((sched) =>
              attendeeTours.find((a) =>
                a.tourScheduleIDs.find((ts) => ts.tourScheduleID === sched.id)
              )
            )
            .map((sched) => {
              const tourDate = new Date(sched.time).toLocaleDateString(
                "en-us",
                {
                  weekday: "long",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                }
              );
              return {
                id: sched.id,
                displayName: `${tour.name} -\n${sched.name} (${tourDate})`,
                date: sched.time,
              };
            })
        );
      }
    });

    const sortedTourSchedules = tourSchedules.sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );

    if (singleSelectedStudent) {
      return (
        <div>
          {sortedTourSchedules.map(({ displayName, id }) => (
            <p key={id}>{displayName}</p>
          ))}
        </div>
      );
    } else {
      const tourSchedulesWithStudents = sortedTourSchedules.map((sched) => ({
        ...sched,
        students: attendeeTours
          .filter((a) =>
            a.tourScheduleIDs.find((ts) => ts.tourScheduleID === sched.id)
          )
          .map((a) => formatFullName(a.firstName, a.lastName)),
      }));

      return (
        <div>
          {tourSchedulesWithStudents.map(({ displayName, id, students }) => (
            <div key={id}>
              <p className="mr-8 mb-8">{displayName}:</p>
              <div className="accent-text four-column-grid mb-8">
                {students.map((studentDisplayName, index) => (
                  <p key={index}>{studentDisplayName}</p>
                ))}
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <div className="student-checkin-details">
      {studentDetails?.length && !!trackCountsNote() && (
        <div className="mb-24">
          <p className="fw-700 flex flex-align-center mb-8">
            <i className="material-icons large-text mr-8">info</i> Track
            Information (Students):
          </p>
          {trackCountsNote()}
        </div>
      )}
      {!!restrictedTrackNote() && (
        <div className="mb-24">
          <p className="fw-700 flex flex-align-center mb-8">
            <i className="material-icons large-text mr-8">warning</i> Restricted
            Track Note:
          </p>
          {restrictedTrackNote()}
        </div>
      )}
      {!!toursList() && (
        <div className="mb-16">
          <p className="fw-700 flex flex-align-center mb-8">
            <i className="material-icons large-text mr-8">directions_subway</i>{" "}
            Tour Enrollments:
          </p>
          {toursList()}
        </div>
      )}
    </div>
  );
}

export default React.memo(CheckInStudentDetails);
