import React, { useState, useCallback } from "react";
import SelectSearch from "react-select";
import { flattenArray, formatFullName } from "../../../../lib";
import _debounce from "lodash.debounce";

function CheckInTableFilters(props) {
  const {
    applyFilters,
    allEnrolledShluchim,
    allEnrolledShluchimLoading,
    allTourSchedules,
    allTracks,
  } = props;

  const [keyword, setKeyword] = useState("");
  const [shliachId, setShliachId] = useState(null);
  const [campusId, setCampusId] = useState(null);
  const [statuses, setStatuses] = useState(["Registered"]);
  const [attendeeTypes, setAttendeeTypes] = useState([]);
  const [tourSchedules, setTourSchedules] = useState([]);
  const [tracks, setTracks] = useState([]);

  const getFiltersObj = useCallback(
    () => ({
      campusId: campusId || "",
      keyword,
      shliachId: shliachId || "",
      statuses,
      tourScheduleIds: tourSchedules.map((t) => t.value),
      trackIds: tracks.map((t) => t.value),
      attendeeTypes: attendeeTypes.map((t) => t.value),
    }),
    [
      campusId,
      keyword,
      shliachId,
      statuses,
      tourSchedules,
      tracks,
      attendeeTypes,
    ]
  );

  const onFilterDebounce = useCallback(
    _debounce((val) => {
      onFilter("keyword", val);
    }, 500),
    [campusId, shliachId, statuses, tourSchedules, tracks, attendeeTypes]
  );

  const onFilter = useCallback(
    (name, value) => {
      applyFilters({ ...getFiltersObj(), [name]: value });
    },
    [applyFilters, getFiltersObj]
  );

  const shluchimOptions =
    allEnrolledShluchim &&
    allEnrolledShluchim.map((s) => ({
      value: s.shliachID,
      label: formatFullName(s.shliachFirstName, s.shliachLastName),
    }));

  const campusOptions =
    allEnrolledShluchim &&
    flattenArray(allEnrolledShluchim.map((s) => s.enrolledCampuses)).map(
      (c) => ({
        value: c.campusID,
        label: c.campusName,
      })
    );
  const allStatuses = ["Registered", "CheckedIn"];
  const allAttendeeTypes = [
    {
      value: "Shliach",
      label: "Shluchim",
    },
    {
      value: "Chaperone",
      label: "Chaperones",
    },
    {
      value: "Student",
      label: "Students",
    },
  ];

  return (
    <div>
      <div className="trip-students-table-filter-inputs mb-16">
        <div className="search-input">
          <input
            type="text"
            placeholder="Search students, shluchim or chaperones"
            name="keyword"
            onChange={(e) => {
              const _keyword = e.target.value;
              setKeyword(_keyword);
              onFilterDebounce(_keyword);
            }}
            value={keyword}
          />
          <i className="material-icons accent-text-secondary flex flex-align-center">
            search
          </i>
        </div>
        <SelectSearch
          className="select-search-input trip-filter-select"
          isClearable={true}
          isSearchable={true}
          onChange={(option) => {
            const _shliachId = option?.value;
            setShliachId(_shliachId);
            onFilter("shliachId", _shliachId);
          }}
          options={shluchimOptions}
          placeholder={
            allEnrolledShluchimLoading
              ? "Loading All Shluchim..."
              : "All Shluchim"
          }
          value={shliachId}
        />
        <SelectSearch
          className="trip-filter-select ml-16"
          isClearable={true}
          isSearchable={true}
          onChange={(option) => {
            const _campusId = option?.value;
            setCampusId(_campusId);
            onFilter("campusId", _campusId);
          }}
          options={campusOptions}
          placeholder={
            allEnrolledShluchimLoading
              ? "Loading All Campuses..."
              : "All Campuses"
          }
          value={campusId}
        />
      </div>
      <div
        className="trip-students-table-filter-inputs"
        style={{ flexWrap: "wrap" }}
      >
        <SelectSearch
          className="trip-filter-select mr-16 mb-16"
          isClearable={true}
          multi={true}
          searchable={false}
          onChange={(arrayStatuses) => {
            const arrayValues = arrayStatuses.map((s) => s.value);
            setStatuses(arrayValues);
            onFilter("statuses", arrayValues);
          }}
          options={allStatuses.map((status) => ({
            value: status,
            label: status,
          }))}
          placeholder="All Statuses"
          value={statuses}
        />
        <SelectSearch
          className="trip-filter-select mr-16 mb-16"
          isClearable={true}
          multi={true}
          searchable={false}
          onChange={(arrayValues) => {
            setAttendeeTypes(arrayValues);
            onFilter(
              "attendeeTypes",
              arrayValues.map((s) => s.value)
            );
          }}
          options={allAttendeeTypes}
          placeholder="All Types"
          value={attendeeTypes}
        />
        <SelectSearch
          className="trip-filter-select mr-16 mb-16"
          isClearable={true}
          multi={true}
          searchable={false}
          onChange={(arrayValues) => {
            setTourSchedules(arrayValues);
            onFilter(
              "tourScheduleIds",
              arrayValues.map((s) => s.value)
            );
          }}
          options={
            allTourSchedules &&
            allTourSchedules.map((tourSchedule) => ({
              value: tourSchedule.id,
              label: tourSchedule.name,
            }))
          }
          placeholder="All Tour Schedules"
          value={tourSchedules}
        />
        <SelectSearch
          className="trip-filter-select mr-16 mb-16"
          isClearable={true}
          multi={true}
          searchable={false}
          onChange={(arrayValues) => {
            setTracks(arrayValues);
            onFilter(
              "trackIds",
              arrayValues.map((s) => s.value)
            );
          }}
          options={
            allTracks &&
            allTracks.map((track) => ({
              value: track.id,
              label: track.trackName,
            }))
          }
          placeholder="All Tracks"
          value={tracks}
        />
      </div>
    </div>
  );
}
export default React.memo(CheckInTableFilters);
