import React from "react";
import ExportCSVButton from "../../../shared/ExportCSVButton";

function CheckInActions(props) {
  const {
    tours,
    getAttendeesForExport,
    hideCheckin,
    onCheckInAttendees,
    tripEvent,
    selectedAttendees,
  } = props;

  const getExportHeaders = () => {
    const tourScheduleColumns = [];

    tours.forEach((tour) => {
      tour.schedules.forEach((tourSchedule) => {
        const scheduleName = `${tour.name} - ${tourSchedule.name}`;
        const scheduleColumns = [
          {
            label: scheduleName,
            value: ({ tourScheduleIDs }) =>
              tourScheduleIDs &&
              tourScheduleIDs
                .map((id) => id.tourScheduleID)
                .indexOf(tourSchedule.id) >= 0
                ? "Y"
                : "N",
          },
        ];
        tourScheduleColumns.push(...scheduleColumns);
      });
    });

    return [
      { label: "ID", value: "id" },
      { label: "First Name", value: "firstName" },
      { label: "Last Name", value: "lastName" },
      { label: "Attendee Type", value: "attendeeType" },
      { label: "Campus ID", value: "campusID" },
      { label: "Campus", value: "campusName" },
      { label: "Chabad House ID", value: "chabadHouseID" },
      { label: "Enrollment ID", value: "enrollmentID" },
      { label: "Shliach ID", value: "shliachID" },
      { label: "Shliach First Name", value: "shliachFirstName" },
      { label: "Shliach Last Name", value: "shliachLastName" },
      { label: "Status", value: "status" },
      ...tourScheduleColumns,
      { label: "Track ID", value: "trackID" },
      { label: "Trip Event ID", value: "tripEventID" },
    ];
  };

  return (
    <div className="flex flex-align-center">
      {!hideCheckin && (
        <button
          className="custom-btn btn btn-accent uppercase-text"
          disabled={
            !selectedAttendees.all && !selectedAttendees.selection.length
          }
          onClick={onCheckInAttendees}
        >
          Check In Attendees
        </button>
      )}
      <ExportCSVButton
        className="custom-btn btn btn-accent uppercase-text ml-16"
        fileName={`${(tripEvent.programScheduleName || "Trip").replace(
          / /g,
          "_"
        )}_Student_Registrations`}
        getExportData={getAttendeesForExport}
        getHeaders={getExportHeaders}
      />
    </div>
  );
}
export default React.memo(CheckInActions);
