import { nanoid } from "nanoid/non-secure";

/** @param {File} file */
export function createUniqueNameForFile(file) {
  const uuid = nanoid(22);
  const parts = file.name.split(".");
  let extension = (parts.length > 1 ? parts.pop() : "").trim();
  if (!extension) {
    extension = getFileExtensionFromMimeType(file.type);
  }
  return `${uuid}.${extension}`;
}

export function getFileExtensionFromMimeType(type) {
  switch (type) {
    case "image/bmp":
      return "bmp";
    case "image/cis-cod":
      return "cod";
    case "image/gif":
      return "gif";
    case "image/ief":
      return "ief";
    case "image/jpeg":
      return "jpeg";
    case "image/jpg":
      return "jpg";
    case "image/pipeg":
      return "jfif";
    case "image/svg+xml":
      return "svg";
    case "image/tiff":
      return "tiff";
    case "image/x-cmu-raster":
      return "ras";
    case "image/x-cmx":
      return "cmx";
    case "image/x-icon":
      return "ico";
    case "image/x-portable-anymap":
      return "pnm";
    case "image/x-portable-bitmap":
      return "pbm";
    case "image/x-portable-graymap":
      return "pgm";
    case "image/x-portable-pixmap":
      return "ppm";
    case "image/x-rgb":
      return "rgb";
    case "image/x-xbitmap":
      return "xbm";
    case "image/x-xpixmap":
      return "xpm";
    case "image/x-xwindowdump":
      return "xwd";
    default:
      return "jpg";
  }
}
