import React, { useMemo, useState } from "react";
import { formatFullName, pluralizeText } from "../../../../lib/utils";
import { Button, Collapse } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

function CheckInShliachChaperoneDetails(props) {
  const { shluchim, chaperones, tracks } = props;

  const [expandShluchim, setExpandShluchim] = useState(false);
  const [expandChaperones, setExpandChaperones] = useState(false);

  const tracksList = useMemo(() => {
    const attendeesWithTracks = [...shluchim, ...chaperones].filter(
      (a) => a.enrollmentTrackIDs?.length
    );
    if (!attendeesWithTracks.length) {
      return null;
    }

    const tracksWithAttendees = [];
    tracks.forEach((track) => {
      const trackAttendees = attendeesWithTracks.filter((a) =>
        a.enrollmentTrackIDs.find((t) => t.trackID === track.id)
      );
      if (trackAttendees.length) {
        tracksWithAttendees.push({
          id: track.id,
          displayName: `${track.trackName}`,
          attendees: trackAttendees.map((a) =>
            formatFullName(a.firstName, a.lastName)
          ),
        });
      }
    });

    return tracksWithAttendees.length ? (
      <div className="mb-16">
        <p className="fw-700 flex flex-align-center mb-8">
          <i className="material-icons large-text mr-8">info</i> Track
          Information (Shluchim & Chaperones):
        </p>
        <div>
          <p>
            You are checking in Shluchim/chaperones with students registered for
            the following tracks:
          </p>
          {tracksWithAttendees.map(({ displayName, id, attendees }) => (
            <div key={id}>
              <p className="mr-8 mb-8">{displayName}:</p>
              <div className="accent-text four-column-grid mb-8">
                {attendees.map((name, index) => (
                  <p key={index}>{name}</p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    ) : null;
  }, [chaperones, shluchim, tracks]);

  return (
    <div className="checkin-shliach-chap-details">
      {Boolean(shluchim.length) && (
        <div className="student-checkin-details">
          <Button
            onClick={() => setExpandShluchim(!expandShluchim)}
            disableRipple
            className="flex"
            size="large"
            variant="text"
          >
            {shluchim.length}{" "}
            {pluralizeText("shliach", shluchim.length, "shluchim")}
            {expandShluchim ? (
              <ExpandLess fontSize="large" />
            ) : (
              <ExpandMore fontSize="large" />
            )}
          </Button>

          <Collapse
            in={expandShluchim}
            className="expanded-list-wrapper accent-text"
          >
            {shluchim.map((s) => (
              <p key={s.id}>{formatFullName(s.firstName, s.lastName)}</p>
            ))}
          </Collapse>
        </div>
      )}

      {Boolean(chaperones.length) && (
        <div className="student-checkin-details">
          <Button
            onClick={() => setExpandChaperones(!expandChaperones)}
            disableRipple
            className="flex"
            size="large"
            variant="text"
          >
            {chaperones.length}{" "}
            {pluralizeText("chaperone", chaperones.length, "chaperones")}
            {expandChaperones ? (
              <ExpandLess fontSize="large" />
            ) : (
              <ExpandMore fontSize="large" />
            )}
          </Button>

          <Collapse
            in={expandChaperones}
            className="expanded-list-wrapper accent-text"
          >
            {chaperones.map((c) => (
              <p key={c.id}>{formatFullName(c.firstName, c.lastName)}</p>
            ))}
          </Collapse>
        </div>
      )}
      {tracksList}
    </div>
  );
}

export default React.memo(CheckInShliachChaperoneDetails);
