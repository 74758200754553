import React from "react";

export default class ShluchimTableHeader extends React.PureComponent {
  render() {
    const {
      eventFeesPaid,
      isTravelTrip,
      selectAllDisabledReason,
      selectedShluchim,
      showSelectAllCheckbox,
      toggleSelectAllShluchim,
      totalShluchim,
    } = this.props;

    const columns = isTravelTrip
      ? [
          "Shliach",
          "Shlucha",
          "Chabad House",
          "Campus",
          "Students Accepted",
          "Actions",
        ]
      : [
          "Shliach",
          "Shlucha",
          "Chabad House",
          "Campus",
          "# of Shluchim + Chaperones",
          "Students Accepted",
          `COCI Owe${eventFeesPaid ? "d" : "s"}`,
          `Shliach Owe${eventFeesPaid ? "d" : "s"}`,
          "Actions",
        ];

    return (
      <div
        className={`trip-shluchim-table-header${
          isTravelTrip ? " travel-trip" : ""
        }`}
      >
        {columns.map((column, index) => (
          <div className="flex flex-align-center" key={index}>
            {index === 0 && showSelectAllCheckbox && (
              <div className="tooltip-container mr-8">
                <div className="flex flex-align-center custom-checkbox-container mr-8">
                  <input
                    className="custom-checkbox"
                    checked={
                      selectedShluchim.all && !selectedShluchim.allExcept.length
                    }
                    disabled={!!selectAllDisabledReason || !totalShluchim}
                    id="all-shluchim"
                    onChange={toggleSelectAllShluchim}
                    type="checkbox"
                  />
                  <label className="flex-align-center" htmlFor="all-shluchim" />
                </div>
                {selectAllDisabledReason && (
                  <span className="tooltip">{selectAllDisabledReason}</span>
                )}
              </div>
            )}
            {column}
          </div>
        ))}
      </div>
    );
  }
}
