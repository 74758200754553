import React from "react";

export default class MergeDuplicateRow extends React.Component {
  state = {
    selectedIndex: null,
    values: [],
    isEditable: false,
  };

  componentDidMount() {
    this.setValues();
  }

  componentDidUpdate(prevProps) {
    const { ids } = this.props;

    if (ids !== prevProps.ids) {
      this.setValues();
    }
  }

  setValues = () => {
    const {
      getDisplayValue,
      isReadonly,
      isEqual,
      name,
      setAsUnresolved,
      students,
    } = this.props;
    var values = students.map((student) => ({
      value: student[name],
      displayValue: getDisplayValue(student[name]),
    }));
    var valuesEqual = isEqual(values.map((v) => v.displayValue));
    this.setState({ values, isEditable: isReadonly ? false : !valuesEqual });
    if (!isReadonly && !valuesEqual) {
      setAsUnresolved(name);
    }
  };

  render() {
    const { selectedIndex, values, isEditable } = this.state;
    const { label, name, isReadonly, resolveField, students, style } =
      this.props;

    return (
      <div
        className="field-row flex flex-align-center"
        style={{
          gridTemplateColumns: `180px repeat(${values.length}, 280px)`,
        }}
      >
        <div style={{ paddingBottom: 8 }}>{label}</div>
        {values.map((val, index) => {
          const { displayValue, value } = val;
          const isSelected = selectedIndex === index;
          const showStudentAccountWarning =
            name === "email" &&
            isSelected &&
            !students[index].didStudentCreateAccount &&
            students.some((s) => s.didStudentCreateAccount);
          return (
            <div
              key={index}
              style={{
                borderLeft: "1px solid #979797",
                paddingLeft: 14,
                paddingBottom: 16,
                color: "rgba(85, 85, 85, 0.5)",
                height: "100%",
              }}
            >
              <div className="flex">
                {isEditable && !isReadonly && (
                  <input
                    type="radio"
                    value={value || ""}
                    style={{ marginRight: 8 }}
                    checked={isSelected}
                    onChange={() => {
                      this.setState({ selectedIndex: index });
                      resolveField(value, name);
                    }}
                  />
                )}
                <div
                  className="tooltip-container"
                  style={
                    isEditable && !isReadonly
                      ? {
                          color: index % 2 === 0 ? "#F3893D" : "#53B7E8",
                        }
                      : {}
                  }
                >
                  {displayValue.length > 28
                    ? displayValue.substring(0, 26) + "..."
                    : displayValue}
                  {displayValue.length > 28 && (
                    <span
                      className="tooltip"
                      style={{ left: 100, ...(style || {}) }}
                    >
                      {displayValue}
                    </span>
                  )}
                </div>
              </div>
              {showStudentAccountWarning && (
                <div className="error-text small-text mt-4">
                  The merged student will need to create a new account.
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}
