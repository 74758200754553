import React from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

export default class OnScreenHebrewKeyboard extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.onChange !== this.props.onChange) {
      return false;
    } else return true;
  }

  render() {
    const { createRef, inputName, onChange } = this.props;

    return (
      <div
        onMouseDown={(e) => {
          e.preventDefault(); //prevent input focus loss when click between keyboard keys
        }}
      >
        <Keyboard
          ref={createRef}
          inputName={inputName}
          layout={{
            default: [
              "פ ם ן ו ט א ר ק - {bksp}",
              "ף ך ל ח י ע כ ג ד ש ׳",
              "ץ ת צ מ נ ה ב ס ז , .",
              "{space}",
            ],
            //complete hebrew keyboard
            // 'default': [
            //     '; 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
            //     '{tab} / ׳ פ ם ן ו ט א ר ק [ ] ֿ',
            //     '{lock} ף ך ל ח י ע כ ג ד ש , {enter}',
            //     '{shift} ץ ת צ מ נ ה ב ס ז . {shift}',
            //     '.com @ {space}'
            // ]
          }}
          onChange={onChange}
          physicalKeyboardHighlight={true}
          preventMouseDownDefault={true}
          stopMouseDownPropagation={true}
          style={{
            position: "absolute",
          }}
        />
      </div>
    );
  }
}
