import React, { Fragment } from "react";
import { browserHistory } from "react-router";
import ConfirmationModal from "../../shared/ConfirmationModal";
import Loader from "../../common/Loader";
import MergeDuplicateForm from "./MergeDuplicateForm";

import StudentsAPI from "../../../services/resources/StudentsApi";
import SystemApi from "../../../services/resources/SystemApi";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";
import { Unauthorized } from "../../../lib/coc-common-components";
import AuthService, { PermissionClaims } from "../../../services/AuthService";
import axios from "axios";
import moment from "moment";
import queryString from "query-string";
import { notify } from "react-notify-toast";

export default class MergeDuplicatePage extends React.PureComponent {
  state = {
    authorized: true,
    errorMessage: "",
    ids: [],
    markNotDuplicateErrorMessage: "",
    markNotDuplicateLoading: false,
    mergeErrorMessage: "",
    loading: true,
    fields: [],
    finalStudent: {},
    showConfirmationModal: false,
    students: [],
    submitMergeLoading: false,
    systemLists: {},
    unresolvedFields: [],
  };

  apiSignal = axios.CancelToken.source();
  incompleteSubmissionErrorMessage =
    "You must select one option for each field that has a conflict";
  defaultValue = "{blank}";

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.StudentMerge)) {
      this.setState({ authorized: false });
    }

    this.setState({ loading: false });
    const { query } = this.props.location;
    this.compareStudents(query);
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  componentDidUpdate() {
    window.onpopstate = (e) => {
      e.preventDefault();
      this.compareStudents(queryString.parse(window.location.search));
    };
  }

  onLeave = () => {
    const { unresolvedFields, isRequestedPageLeave } = this.state;

    if (!isRequestedPageLeave && unresolvedFields.length > 0) {
      return "Are you sure you want to leave this page?  Your unsaved changes will be lost.";
    }
  };

  compareStudents = async ({ id: ids }) => {
    this.setState({
      loading: true,
    });
    try {
      const systemLists = await SystemApi.lists([
        "genders",
        "hebrewLevels",
        "jewishBackgroundTypes",
        "jewishIsraelKnowledgeLevels",
        "jewishnessStatuses",
        "jewishObservanceStatuses",
        "studentClasses",
        "tShirtSizes",
        "participationStatuses",
        "observanceStatuses",
        "shabbosHolidayObservanceStatuses",
        "jewishFamilyAffiliationTypes",
        "halachicJewishStatuses",
      ]);
      const students = await StudentsAPI.compareStudents(
        this.apiSignal.token,
        ids,
      );
      const reorderedStudentsList = ids.map((id) =>
        students.find((s) => s.studentID === parseInt(id)),
      );
      this.setState(
        {
          finalStudent: reorderedStudentsList.length
            ? reorderedStudentsList[0]
            : {},
          loading: false,
          students: reorderedStudentsList,
          systemLists,
        },
        () => this.getStudentFields(),
      );
    } catch (err) {
      if (!axios.isCancel(err)) {
        const is404 = (err.response && err.response.status) === 404;
        this.setState({
          errorMessage: is404
            ? "One or more of the requested students could not be found."
            : ApiCallErrorMessageHandler(
                err,
                "Sorry, something went wrong. Please try again.",
              ),
          loading: false,
        });
      }
    }
  };

  removeStudent = (index) => {
    const { students } = this.state;
    students.splice(index, 1);

    this.setState(
      {
        finalStudent: students.length ? students[0] : {},
        students,
      },
      () => {
        const studentIds = students.map((s) => s.studentID);
        studentIds.length > 1
          ? browserHistory.push(
              `/students/merge?${queryString.stringify({
                id: studentIds,
              })}`,
            )
          : browserHistory.push(`/students`);
      },
    );
  };

  getStudentFields = () => {
    const {
      systemLists: {
        genders,
        hebrewLevels,
        jewishBackgroundTypes,
        jewishIsraelKnowledgeLevels,
        jewishnessStatuses,
        jewishObservanceStatuses,
        studentClasses,
        tShirtSizes,
        participationStatuses,
        observanceStatuses,
        shabbosHolidayObservanceStatuses,
        jewishFamilyAffiliationTypes,
        halachicJewishStatuses,
      },
    } = this.state;
    const fieldsToResolve = [];
    const fields = [
      {
        name: "createdOn",
        label: "Date Created",
        isReadonly: true,
        getDisplayValue: (value) =>
          moment(value).isValid()
            ? moment(value).format("MM/DD/YYYY")
            : this.defaultValue,
      },
      {
        name: "createdBy",
        label: "Created By",
        isReadonly: true,
        getDisplayValue: (value) => value || this.defaultValue,
      },
      {
        name: "email",
        label: "Email",
        getDisplayValue: (value) =>
          value && value.value ? value.value : this.defaultValue,
      },
      {
        name: "firstName",
        label: "First Name",
        getDisplayValue: (value) => value || this.defaultValue,
      },
      {
        name: "lastName",
        label: "Last Name",
        getDisplayValue: (value) => value || this.defaultValue,
      },
      {
        name: "legalFirstName",
        label: "Legal First Name",
        getDisplayValue: (value) => value || this.defaultValue,
      },
      {
        name: "legalMiddleName",
        label: "Legal Middle Name",
        getDisplayValue: (value) => value || this.defaultValue,
      },
      {
        name: "legalLastName",
        label: "Legal Last Name",
        getDisplayValue: (value) => value || this.defaultValue,
      },
      {
        name: "isArchived",
        label: "Archived",
        getDisplayValue: (value) =>
          value == null ? this.defaultValue : value ? "Yes" : "No",
      },
      {
        name: "hebrewName",
        label: "Hebrew Name",
        getDisplayValue: (value) => value || this.defaultValue,
      },
      {
        name: "dob",
        label: "Birthday",
        getDisplayValue: (value) =>
          moment(value).isValid()
            ? moment.utc(value).format("MM/DD/YYYY")
            : this.defaultValue,
      },
      {
        name: "phone",
        label: "Phone",
        getDisplayValue: (value) =>
          value && value.value ? value.value : this.defaultValue,
      },
      {
        name: "shirtSize",
        label: "Shirt Size",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(tShirtSizes, value),
      },
      {
        name: "gender",
        label: "Gender",
        getDisplayValue: (value) => this.getTypeDisplayValue(genders, value),
      },
      {
        name: "isJewish",
        label: "Jewish",
        getDisplayValue: (value) =>
          value == null ? this.defaultValue : value ? "Yes" : "No",
      },
      {
        name: "address",
        label: "Address",
        getDisplayValue: (value) =>
          value && value.value ? value.value : this.defaultValue,
      },
      {
        name: "campus",
        label: "School",
        getDisplayValue: (value) =>
          value && value.value ? value.value : this.defaultValue,
      },
      {
        name: "class",
        label: "Demographic",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(studentClasses, value),
      },
      {
        name: "major",
        label: "Major",
        getDisplayValue: (value) =>
          value && value.value ? value.value : this.defaultValue,
      },
      {
        name: "doubleMajor",
        label: "Double Major",
        getDisplayValue: (value) =>
          value && value.value ? value.value : this.defaultValue,
      },
      {
        name: "graduatingYear",
        label: "Graduating Year",
        getDisplayValue: (value) => value || this.defaultValue,
      },
      {
        name: "studentIDNumber",
        label: "School Student ID",
        getDisplayValue: (value) => value || this.defaultValue,
      },
      {
        name: "fatherBackground",
        label: "Father's Background",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(jewishBackgroundTypes, value),
      },
      {
        name: "motherBackground",
        label: "Mother's Background",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(jewishBackgroundTypes, value),
      },
      {
        name: "fatherName",
        label: "Father Name",
        getDisplayValue: (value) => value || this.defaultValue,
      },
      {
        name: "fatherEmail",
        label: "Father Email",
        getDisplayValue: (value) => value || this.defaultValue,
      },
      {
        name: "fatherPhone",
        label: "Father Phone",
        getDisplayValue: (value) => value || this.defaultValue,
      },
      {
        name: "motherName",
        label: "Mother Name",
        getDisplayValue: (value) => value || this.defaultValue,
      },
      {
        name: "motherEmail",
        label: "Mother Email",
        getDisplayValue: (value) => value || this.defaultValue,
      },
      {
        name: "motherPhone",
        label: "Mother Phone",
        getDisplayValue: (value) => value || this.defaultValue,
      },
      {
        name: "doParentsShareResidence",
        label: "Parents Same Address",
        getDisplayValue: (value) =>
          value == null ? this.defaultValue : value ? "Yes" : "No",
      },
      {
        name: "hebrewHigh",
        label: "Hebrew High",
        getDisplayValue: (value) =>
          value && value.value ? value.value : this.defaultValue,
      },
      {
        name: "hebrewSchool",
        label: "Hebrew School",
        getDisplayValue: (value) =>
          value && value.value ? value.value : this.defaultValue,
      },
      {
        name: "jewishDaySchool",
        label: "Jewish Day School",
        getDisplayValue: (value) =>
          value && value.value ? value.value : this.defaultValue,
      },
      {
        name: "jewishHighSchool",
        label: "Jewish High School",
        getDisplayValue: (value) =>
          value && value.value ? value.value : this.defaultValue,
      },
      {
        name: "jewishSummerCamp",
        label: "Jewish Summer Camp",
        getDisplayValue: (value) =>
          value && value.value ? value.value : this.defaultValue,
      },
      {
        name: "otherJewishClasses",
        label: "Other Jewish Classes",
        getDisplayValue: (value) =>
          value && value.value ? value.value : this.defaultValue,
      },
      {
        name: "hebrewLevel",
        label: "Hebrew Language",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(hebrewLevels, value),
      },
      {
        name: "hadBarBatMitzvah",
        label: "Had Bar/Bat Mitzvah",
        getDisplayValue: (value) =>
          value == null ? this.defaultValue : value ? "Yes" : "No",
      },
      {
        name: "hasTakenIsraelCourses",
        label: "Courses about Israel",
        getDisplayValue: (value) =>
          value == null ? this.defaultValue : value ? "Yes" : "No",
      },
      {
        name: "jewishIsraelKnowledgeLevel",
        label: "Connection to Israel",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(jewishIsraelKnowledgeLevels, value),
      },
      {
        name: "hasAllergies",
        label: "Has Allergies",
        getDisplayValue: (value) =>
          value == null ? this.defaultValue : value ? "Yes" : "No",
      },
      {
        name: "vegetarian",
        label: "Vegetarian",
        getDisplayValue: (value) =>
          value == null ? this.defaultValue : value ? "Yes" : "No",
      },
      {
        name: "vegan",
        label: "Vegan",
        getDisplayValue: (value) =>
          value == null ? this.defaultValue : value ? "Yes" : "No",
      },
      {
        name: "glutenFree",
        label: "Gluten Free",
        getDisplayValue: (value) =>
          value == null ? this.defaultValue : value ? "Yes" : "No",
      },
      {
        name: "milkAllergy",
        label: "Milk Allergy",
        getDisplayValue: (value) =>
          value == null ? this.defaultValue : value ? "Yes" : "No",
      },
      {
        name: "treeNutAllergy",
        label: "Tree Nut Allergy",
        getDisplayValue: (value) =>
          value == null ? this.defaultValue : value ? "Yes" : "No",
      },
      {
        name: "peanutAllergy",
        label: "Peanut Allergy",
        getDisplayValue: (value) =>
          value == null ? this.defaultValue : value ? "Yes" : "No",
      },
      {
        name: "wheatAllergy",
        label: "Wheat Allergy",
        getDisplayValue: (value) =>
          value == null ? this.defaultValue : value ? "Yes" : "No",
      },
      {
        name: "fishAllergy",
        label: "Fish Allergy",
        getDisplayValue: (value) =>
          value == null ? this.defaultValue : value ? "Yes" : "No",
      },
      {
        name: "eggAllergy",
        label: "Egg Allergy",
        getDisplayValue: (value) =>
          value == null ? this.defaultValue : value ? "Yes" : "No",
      },
      {
        name: "soyAllergy",
        label: "Soy Allergy",
        getDisplayValue: (value) =>
          value == null ? this.defaultValue : value ? "Yes" : "No",
      },
      {
        name: "sesameAllergy",
        label: "Sesame Allergy",
        getDisplayValue: (value) =>
          value == null ? this.defaultValue : value ? "Yes" : "No",
      },
      {
        name: "otherAllergy",
        label: "Other Allergy",
        getDisplayValue: (value) =>
          value == null ? this.defaultValue : value ? "Yes" : "No",
      },
      {
        name: "dietaryNotes",
        label: "Additional Dietary Information and/or Severity of Allergies",
        getDisplayValue: (value) => value || this.defaultValue,
      },
      {
        name: "cityOfOrigin",
        label: "City of origin",
        getDisplayValue: (value) => value || this.defaultValue,
      },
      {
        name: "cityOfOriginJewishCommunityType",
        label: "City of origin Jewish stats",
        getDisplayValue: (value) => value || this.defaultValue,
      },
      {
        name: "cityOfOriginRegion",
        label: "City of origin region",
        getDisplayValue: (value) =>
          value && value.value ? value.value : this.defaultValue,
      },
      {
        name: "halachicJewishStatus",
        label: "Halachic Jewish status",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(halachicJewishStatuses, value),
      },
      {
        name: "shliachOverrideJewishnessStatus",
        label: "Shliach override - is student Jewish",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(jewishnessStatuses, value),
      },
      {
        name: "shliachOverrideJewishnessNote",
        label: "Shliach override note",
        getDisplayValue: (value) => value || this.defaultValue,
      },
      {
        name: "familyJewishAffiliation",
        label: "Family Jewish affiliation",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(jewishFamilyAffiliationTypes, value),
      },
      {
        name: "childhoodJewishObservanceStatus",
        label: "Childhood Observance",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(jewishObservanceStatuses, value),
      },
      {
        name: "childhoodShabbosHolidayStatus",
        label: "Childhood Shabbos/Holiday",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(shabbosHolidayObservanceStatuses, value),
      },
      {
        name: "childhoodSynagogueAttendanceStatus",
        label: "Childhood Synagogue attendance",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(participationStatuses, value),
      },
      {
        name: "childhoodTorahStudyStatus",
        label: "Childhood Torah study",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(participationStatuses, value),
      },
      {
        name: "childhoodTefillinStatus",
        label: "Childhood Tefillin",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(participationStatuses, value),
      },
      {
        name: "childhoodKosherStatus",
        label: "Childhood Kosher",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(observanceStatuses, value),
      },
      {
        name: "currentJewishObservanceStatus",
        label: "Current Observance",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(jewishObservanceStatuses, value, true),
      },
      {
        name: "currentSynagogueAttendanceStatus",
        label: "Synagogue attendance",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(participationStatuses, value, true),
      },
      {
        name: "currentTorahStudyStatus",
        label: "Torah study",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(participationStatuses, value, true),
      },
      {
        name: "currentShabbosHolidayStatus",
        label: "Shabbos/Holiday",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(
            shabbosHolidayObservanceStatuses,
            value,
            true,
          ),
      },
      {
        name: "currentTefillinStatus",
        label: "Tefillin",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(participationStatuses, value, true),
      },
      {
        name: "currentKosherStatus",
        label: "Kosher",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(observanceStatuses, value, true),
      },
      {
        name: "jewishAffiliation",
        label: "Jewish affiliation",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(jewishFamilyAffiliationTypes, value, true),
      },
      {
        name: "shabbatDinnerParticipation",
        label: "Shabbat dinner",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(participationStatuses, value, true),
      },
      {
        name: "socialEventsParticipation",
        label: "Social events",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(participationStatuses, value, true),
      },
      {
        name: "learningEventsParticipation",
        label: "Learning events",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(participationStatuses, value, true),
      },
      {
        name: "personalShliachShluchaTimeParticipation",
        label: "Personal shliach time",
        getDisplayValue: (value) =>
          this.getTypeDisplayValue(participationStatuses, value, true),
      },
      {
        name: "referralSourceDescription",
        label: "Referral source",
        getDisplayValue: (value) => value || this.defaultValue,
      },
      {
        name: "notes",
        label: "Notes",
        getDisplayValue: (value) => value || this.defaultValue,
      },
      {
        name: "orgInvolvementTypes",
        label: "Org involvement",
        getDisplayValue: (value) =>
          value && value.value.length
            ? value?.value.join(", ")
            : this.defaultValue,
      },
      {
        name: "programParticipationTypes",
        label: "Program participation",
        getDisplayValue: (value) =>
          value && value.value.length
            ? value?.value.join(", ")
            : this.defaultValue,
      },
      {
        name: "interestTypes",
        label: "Interests",
        getDisplayValue: (value) =>
          value && value.value.length
            ? value?.value.join(", ")
            : this.defaultValue,
      },
      {
        name: "groups",
        label: "Student Groups",
        getDisplayValue: (value) =>
          value && value.value.length
            ? value?.value.join(", ")
            : this.defaultValue,
      },
    ].map((f) => ({
      ...f,
      setAsUnresolved: (name) => fieldsToResolve.push(name),
    }));
    this.setState({ unresolvedFields: fieldsToResolve, fields });
  };

  getTypeDisplayValue = (typeList, value, isKeyValue) => {
    const fieldValue = isKeyValue ? value?.value : value;
    const index = typeList.findIndex((t) => t.enumValue === fieldValue);
    return index > -1 ? typeList[index].displayValue : this.defaultValue;
  };

  resolveField = (val, name) => {
    const { finalStudent, unresolvedFields } = this.state;
    this.setState(
      {
        finalStudent: {
          ...finalStudent,
          [name]: val,
        },
        unresolvedFields: unresolvedFields.filter((f) => f !== name),
      },
      () => {
        if (!this.state.unresolvedFields.length) {
          this.setState({ mergeErrorMessage: "" });
        }
      },
    );
  };

  merge = async () => {
    const { finalStudent, unresolvedFields } = this.state;
    const {
      query: { id: ids },
    } = this.props.location;
    try {
      this.setState({ showConfirmationModal: false });
      if (unresolvedFields.length) {
        this.setState({
          mergeErrorMessage: this.incompleteSubmissionErrorMessage,
        });
        return;
      }
      this.setState({ submitMergeLoading: true });
      const response = await StudentsAPI.mergeDuplicates(
        this.apiSignal.token,
        ids,
        finalStudent,
      );
      notify.show("Students have been successfully merged", "success");
      browserHistory.push(`/students/${response.studentID}`);
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          mergeErrorMessage: ApiCallErrorMessageHandler(err),
          submitMergeLoading: false,
        });
      }
    }
  };

  markAsNotDuplicate = async (id) => {
    this.setState({ markNotDuplicateLoading: true });
    try {
      await StudentsAPI.markNotDuplicate(id, this.apiSignal.token);
      notify.show("Students have been marked as non duplicate", "success");
      browserHistory.goBack();
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          markNotDuplicateLoading: false,
          markNotDuplicateErrorMessage: ApiCallErrorMessageHandler(err),
        });
      }
    }
  };

  render() {
    const {
      authorized,
      errorMessage,
      fields,
      loading,
      markNotDuplicateErrorMessage,
      markNotDuplicateLoading,
      mergeErrorMessage,
      showConfirmationModal,
      students,
      submitMergeLoading,
      unresolvedFields,
    } = this.state;

    const {
      query: { id: ids, suggestionId },
    } = this.props.location;

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    return (
      <div className="page col-xs-12 mt-16">
        {errorMessage ? (
          <div className="full-page-error-text error-text">
            <img src="/img/error.svg" alt="error robot" height="240" />
            <p>{errorMessage}</p>
          </div>
        ) : (
          <div className="merge-duplicates-card full-width">
            {loading ? (
              <div className="full-page-loader">
                <Loader />
              </div>
            ) : (
              <Fragment>
                <div className="mb-40 mt-16 flex flex-align-center flex-justify-space">
                  <div className="flex flex-align-center">
                    <i
                      className="material-icons link-text xl-text mr-12"
                      onClick={() => browserHistory.goBack()}
                    >
                      arrow_back
                    </i>
                    <p className="flex flex-align-center xxl-text">
                      Select one option for each conflict
                    </p>
                  </div>
                  {!!suggestionId && (
                    <div>
                      <button
                        className="btn custom-btn not-duplicate-btn uppercase-text"
                        disabled={markNotDuplicateLoading}
                        onClick={() => this.markAsNotDuplicate(suggestionId)}
                      >
                        {markNotDuplicateLoading
                          ? "Marking Not Duplicate..."
                          : "Mark Not Duplicate"}
                      </button>
                      {markNotDuplicateErrorMessage && (
                        <p className="small-text error-text">
                          {markNotDuplicateErrorMessage}
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <div>
                  <MergeDuplicateForm
                    fields={fields}
                    ids={ids}
                    students={students}
                    resolveField={this.resolveField}
                    removeStudent={this.removeStudent}
                  />
                  <div className="merge-duplicates-form-footer">
                    <p style={{ color: "#53B7E8" }}>
                      History will be merged from all accounts. Review student
                      activity after merge to ensure there are no duplicate
                      interactions.
                      <br />
                      Merged student will{" "}
                      {students.find((s) => !!s.isActive)
                        ? "be set to active"
                        : "remain inactive"}
                      .
                    </p>
                    {mergeErrorMessage && (
                      <p
                        className="error-text"
                        style={{ textAlign: "right", marginBottom: 8 }}
                      >
                        {mergeErrorMessage}
                      </p>
                    )}

                    <div className="merge-duplicates-btns">
                      {submitMergeLoading ? (
                        <div className="medium-loader">
                          <Loader />
                        </div>
                      ) : (
                        <Fragment>
                          <button
                            className="cancel-btn btn link-text uppercase-text fw-500 mr-20"
                            onClick={() => browserHistory.goBack()}
                          >
                            Cancel
                          </button>
                          <button
                            className="merge-btn btn uppercase-text fw-500"
                            disabled={unresolvedFields.length}
                            onClick={() =>
                              this.setState({ showConfirmationModal: true })
                            }
                          >
                            Merge
                          </button>
                        </Fragment>
                      )}
                    </div>
                  </div>
                  <ConfirmationModal
                    cancel={() =>
                      this.setState({ showConfirmationModal: false })
                    }
                    confirm={this.merge}
                    confirmText="Yes"
                    message={`Are you sure you want to merge these student accounts? This cannot be undone!`}
                    show={showConfirmationModal}
                    noTitle={true}
                  />
                </div>
              </Fragment>
            )}
          </div>
        )}
      </div>
    );
  }
}
