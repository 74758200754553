import React, { useCallback, useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "@material-ui/core";
import ConfirmationModal from "../../../../shared/ConfirmationModal";
import RegistrationDeleteButton from "./details/RegistrationDeleteButton";
import RegistrationMedical from "./details/RegistrationMedical";
import RegistrationNotes from "./details/RegistrationNotes";
import RegistrationOverview from "./details/RegistrationOverview";
import RegistrationPayment from "./details/RegistrationPayment";
import RegistrationPersonal from "./details/RegistrationPersonal";
import RegistrationRecommendation from "./details/RegistrationRecommendation";
import RegistrationTours from "./details/RegistrationTours";

import TripEventsApi from "../../../../../services/resources/TripEventsApi";
import { TripRegistrationStatuses, TripTypes } from "../../../TripEventConsts";
import { ApiCallErrorMessageHandler } from "../../../../../lib/coc-common-scripts";
import { formatCurrency, getFormattedValuesForForm } from "../../../../../lib";
import { notify } from "react-notify-toast";
import Big from "big.js";
import axios from "axios";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";

const tripTabTypes = {
  Personal: "Personal",
  Medical: "Medical",
  Tours: "Tours",
  Recommendation: "Recommendation",
  Notes: "Notes",
  Payment: "Payment & Cancellations",
};

const nonTraveTripTabTypes = [
  tripTabTypes.Medical,
  tripTabTypes.Tours,
  tripTabTypes.Payment,
];

const travelTripTabTypes = [
  tripTabTypes.Personal,
  tripTabTypes.Medical,
  tripTabTypes.Recommendation,
  tripTabTypes.Notes,
  tripTabTypes.Payment,
];

const tripTabs = [
  { type: tripTabTypes.Personal, component: RegistrationPersonal },
  { type: tripTabTypes.Medical, component: RegistrationMedical },
  { type: tripTabTypes.Tours, component: RegistrationTours },
  { type: tripTabTypes.Recommendation, component: RegistrationRecommendation },
  { type: tripTabTypes.Notes, component: RegistrationNotes },
  { type: tripTabTypes.Payment, component: RegistrationPayment },
];

function RegistrationDetails(props) {
  const {
    programScheduleId,
    readOnlyAccess,
    route,
    router,
    shliachEnrollment,
    tripEvent,
  } = props;
  const { enrollmentID } = shliachEnrollment;

  const [currentTabType, setCurrentTabType] = useState();

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [registration, setRegistration] = useState(
    getFormattedValuesForForm(props.registration)
  );
  const [initialRegistration, setInitialRegistration] = useState(
    _cloneDeep(registration)
  );

  // Registration notes are loaded from the Notes tabs and stored here to avoid reloading each time the tab is accessed.
  // The value is initialized to undefined to track whether they were loaded.
  const [registrationNotes, setRegistrationNotes] = useState();
  // Active registraton note details are stored here to preserve the note when moving between tabs and to prompt for unsaved changes.
  const [activeRegistrationNote, setActiveRegistrationNote] = useState();

  const [
    showSaveChangesConfirmationModal,
    setShowSaveChangesConfirmationModal,
  ] = useState(false);

  const [registrationPriceChange, setRegistrationPriceChange] = useState(0);

  const apiSignalRef = useRef(axios.CancelToken.source());

  useEffect(() => {
    const _apiSignal = apiSignalRef.current;
    return () => _apiSignal.cancel();
  }, []);

  useEffect(() => {
    router.setRouteLeaveHook(route, () => {
      if (
        !_isEqual(registration, initialRegistration) ||
        activeRegistrationNote
      ) {
        return `${
          activeRegistrationNote
            ? "You have unsaved note changes"
            : "The changes you made have not been saved"
        }. Are you sure you want to leave this page?`;
      }
    });
  }, [
    activeRegistrationNote,
    initialRegistration,
    registration,
    route,
    router,
  ]);

  const onRegistrationUpdated = useCallback((updatedRegistration) => {
    const formattedRegistration =
      getFormattedValuesForForm(updatedRegistration);

    setRegistration(formattedRegistration);
    setInitialRegistration(_cloneDeep(formattedRegistration));
    setRegistrationPriceChange(0);
  }, []);

  const onChangeRegistration = useCallback(
    (name, value, otherUpdates) => {
      const updatedRegistration = _cloneDeep(registration);

      _set(updatedRegistration, name, value);

      if (otherUpdates) {
        Object.keys(otherUpdates).forEach((update) =>
          _set(updatedRegistration, update, otherUpdates[update])
        );
      }

      setRegistration(updatedRegistration);

      const didUpdateRegistration = !_isEqual(
        initialRegistration,
        updatedRegistration
      );
      let priceChange = Big(0);

      const { tours, trackID, trackPrice } = updatedRegistration;

      if (didUpdateRegistration) {
        if (trackID !== initialRegistration.trackID) {
          const initialTrackPrice = Big(initialRegistration.trackPrice);
          const updatedTrackPrice = Big(trackPrice);

          const trackPriceChange = updatedTrackPrice.minus(initialTrackPrice);
          priceChange = priceChange.plus(trackPriceChange);
        }

        if (!_isEqual(tours, initialRegistration.tours)) {
          let initialToursPrice = Big(0);
          initialRegistration.tours.forEach((tour) => {
            const tourPrice = Big(tour.price || 0);
            initialToursPrice = initialToursPrice.plus(tourPrice);
          });

          let updatedToursPrice = Big(0);
          tours.forEach((tour) => {
            const tourPrice = Big(tour.price || 0);
            updatedToursPrice = updatedToursPrice.plus(tourPrice);
          });

          const toursPriceChange = updatedToursPrice.minus(initialToursPrice);
          priceChange = priceChange.plus(toursPriceChange);
        }
      }

      setRegistrationPriceChange(priceChange);
    },
    [initialRegistration, registration]
  );

  const onCancelRegistrationChanges = useCallback(() => {
    setRegistration(initialRegistration);
  }, [initialRegistration]);

  const onSaveRegistrationChanges = useCallback(async () => {
    setErrorMessage("");
    setLoading(true);

    try {
      const updatedRegistration = await TripEventsApi.updateTripRegistration(
        apiSignalRef.current.token,
        {
          busNumber: registration.busNumber,
          enrollmentID, //required shliach enrollment Id for BE validation purposes (to ensure that enrollment was not changed after registration)
          flightDetails: registration.flightDetails,
          id: registration.id,
          tourScheduleIDs: Array.prototype.concat.apply(
            [],
            registration.tours.map((tour) =>
              tour.schedules.map((sched) => sched.id)
            )
          ),
          trackID: registration.trackID,
          wasParentLetterSubmitted: registration.wasParentLetterSubmitted,
          wasSurveySubmitted: registration.wasSurveySubmitted,
        }
      );

      //for standard updates API is not returning credCardInfo to avoid unnecessary stripe requests being that cc info will not change.
      //t/f setting credCardInfo field to initial registration credCardInfo.
      updatedRegistration.credCardInfo = registration.credCardInfo;

      onRegistrationUpdated(updatedRegistration);

      setShowSaveChangesConfirmationModal(false);

      notify.show("Your changes have been saved", "success");
    } catch (err) {
      if (!axios.isCancel(err)) {
        setErrorMessage(ApiCallErrorMessageHandler(err));
      }
    }

    setLoading(false);
  }, [
    enrollmentID,
    onRegistrationUpdated,
    registration.busNumber,
    registration.credCardInfo,
    registration.flightDetails,
    registration.id,
    registration.tours,
    registration.trackID,
    registration.wasParentLetterSubmitted,
    registration.wasSurveySubmitted,
  ]);

  const {
    hasParentLetters,
    hasTripEnded,
    isPassportIdRequired,
    isProofOfVaccinationRequired,
    isTravelTrip,
    programScheduleName,
    rewardCreditValue,
    tours,
    type,
  } = tripEvent;

  const tabs = tripTabs.filter(
    (t) =>
      (isTravelTrip ? travelTripTabTypes : nonTraveTripTabTypes).includes(
        t.type
      ) &&
      (tours.length || t.type !== tripTabTypes.Tours)
  );

  const TabComponent = (tabs.find((t) => t.type === currentTabType) || tabs[0])
    .component;

  const allowUpdateRegistrationDetails = // details: track, tours, parent letter, transportion, post trip details
    !readOnlyAccess &&
    registration.status !== TripRegistrationStatuses.Rejected &&
    registration.status !== TripRegistrationStatuses.Cancelled;

  return (
    <>
      <RegistrationOverview
        allowUpdate={allowUpdateRegistrationDetails}
        hasParentLetters={hasParentLetters}
        hasTripEnded={hasTripEnded}
        initialRegistration={initialRegistration}
        isTravelTrip={isTravelTrip}
        onChangeRegistration={onChangeRegistration}
        onRegistrationUpdated={onRegistrationUpdated}
        programScheduleName={programScheduleName}
        readOnlyAccess={readOnlyAccess}
        registration={registration}
        rewardCreditValue={rewardCreditValue}
        shliachEnrollment={shliachEnrollment}
        saveCancelControl={
          allowUpdateRegistrationDetails ? (
            <div className="flex flex-align-center">
              <button
                className="btn custom-btn btn-light btn-medium mr-16 uppercase-text"
                disabled={
                  _isEqual(initialRegistration, registration) || loading
                }
                onClick={onCancelRegistrationChanges}
              >
                Cancel
              </button>
              <button
                className="btn custom-btn btn-accent btn-medium mr-16 uppercase-text"
                disabled={
                  _isEqual(initialRegistration, registration) || loading
                }
                onClick={() => setShowSaveChangesConfirmationModal(true)}
              >
                Save Changes
                {registrationPriceChange > 0 || registrationPriceChange < 0
                  ? ` & ${
                      registrationPriceChange > 0 ? "Charge" : "Refund"
                    } $${formatCurrency(Math.abs(registrationPriceChange))}`
                  : ""}
              </button>
            </div>
          ) : null
        }
      />
      <Tabs
        className="trip-student-detail-tabs"
        value={currentTabType || tabs[0].type}
        onChange={(_, val) => setCurrentTabType(val)}
      >
        {tabs.map((tab) => (
          <Tab key={tab.type} label={tab.type} value={tab.type} />
        ))}
      </Tabs>
      <TabComponent
        initialRegistration={initialRegistration}
        isTravelTrip={isTravelTrip}
        key={currentTabType || "default"}
        onChangeRegistration={onChangeRegistration}
        onRegistrationUpdated={onRegistrationUpdated}
        readOnlyAccess={readOnlyAccess}
        registration={registration}
        shliachEnrollment={shliachEnrollment}
        {...(TabComponent === RegistrationTours
          ? {
              allowUpdate: allowUpdateRegistrationDetails,
            }
          : TabComponent === RegistrationNotes
          ? {
              registrationNotes,
              setRegistrationNotes,
              activeRegistrationNote,
              setActiveRegistrationNote,
            }
          : TabComponent === RegistrationPayment
          ? {
              programScheduleId,
              tripType: type,
            }
          : TabComponent === RegistrationMedical
          ? {
              isProofOfVaccinationRequired,
            }
          : {
              isLivingLinksTrip: type === TripTypes.LivingLinks,
              isPassportIdRequired,
            })}
      />
      {!readOnlyAccess && (
        <RegistrationDeleteButton
          programScheduleId={programScheduleId}
          registration={registration}
        />
      )}
      <ConfirmationModal
        cancel={() => {
          setShowSaveChangesConfirmationModal(false);
          setErrorMessage("");
        }}
        confirm={onSaveRegistrationChanges}
        className="trip-modal"
        errorMessage={errorMessage}
        loading={loading}
        message={`Are you sure you want to save changes to this registration${
          registrationPriceChange > 0 || registrationPriceChange < 0
            ? ` and ${
                registrationPriceChange > 0
                  ? registration.wasPaymentProcessed
                    ? "charge"
                    : "add"
                  : registration.wasPaymentProcessed
                  ? "refund"
                  : "deduct"
              } $${formatCurrency(Math.abs(registrationPriceChange))}${
                !registration.wasPaymentProcessed
                  ? ` ${
                      registrationPriceChange > 0 ? "to" : "from"
                    } the pending payment`
                  : ""
              }`
            : ""
        }?`}
        show={showSaveChangesConfirmationModal}
      />
    </>
  );
}

export default React.memo(RegistrationDetails);
