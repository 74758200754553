import React, { useCallback, useState, useEffect } from "react";
import Modal from "react-modal";
import Loader from "../../../common/Loader";
import { formatFullName, pluralizeText } from "../../../../lib/utils";
import CheckInStudentDetails from "./CheckInStudentDetails";
import CheckInShliachChaperoneDetails from "./CheckInShliachChaperoneDetails";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import axios from "axios";

function CheckInModal(props) {
  const {
    show,
    getAttendeesDetailsForCheckin,
    onCancel,
    onConfirm,
    errorMessage: submissionErrorMessage,
    submissionLoading,
    selectedAttendees,
    tours,
    tracks,
  } = props;

  const [shluchimDetails, setShluchimDetails] = useState([]);
  const [chaperoneDetails, setChaperoneDetails] = useState([]);
  const [studentDetails, setStudentDetails] = useState([]);
  const [detailsErrorMessage, setDetailsErrorMessage] = useState("");
  const [detailsLoading, setDetailsLoading] = useState(false);

  const getAttendeeDetails = useCallback(() => {
    setShluchimDetails([]);
    setChaperoneDetails([]);
    setStudentDetails([]);
    setDetailsErrorMessage("");
    setDetailsLoading(true);

    getAttendeesDetailsForCheckin()
      .then((response) => {
        const attendeeDetails = response?.filter(
          (a) => a.status === "Registered"
        );
        if (!attendeeDetails.length) {
          setDetailsErrorMessage("No results");
        }

        setShluchimDetails(
          attendeeDetails.filter((a) => a.attendeeType === "Shliach")
        );
        setChaperoneDetails(
          attendeeDetails.filter((a) => a.attendeeType === "Chaperone")
        );
        setStudentDetails(
          attendeeDetails.filter((a) => a.attendeeType === "Student")
        );

        setDetailsLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDetailsErrorMessage(ApiCallErrorMessageHandler(err));
          setDetailsLoading(false);
        }
      });
  }, [getAttendeesDetailsForCheckin]);

  useEffect(() => {
    getAttendeeDetails();
  }, [getAttendeeDetails]);

  const attendeesForCheckInText = useCallback(() => {
    if (selectedAttendees.all)
      return "selected attendees for the applied filters";

    if (selectedAttendees.selection.length === 1) {
      const attendee = selectedAttendees.selection[0];
      return `${attendee.attendeeType.toLowerCase()}, ${formatFullName(
        attendee.firstName,
        attendee.lastName
      )}`;
    }
    // else, display number of each type of attendee, comma delimited
    const numShluchim = selectedAttendees.selection.filter(
      (a) => a.attendeeType === "Shliach"
    ).length;
    const numChaperones = selectedAttendees.selection.filter(
      (a) => a.attendeeType === "Chaperone"
    ).length;
    const numStudents = selectedAttendees.selection.filter(
      (a) => a.attendeeType === "Student"
    ).length;

    const shluchimDisplay = pluralizeText("shliach", numShluchim, "shluchim");
    const chaperoneDisplay = pluralizeText("chaperone", numChaperones);
    const studentDisplay = pluralizeText("student", numStudents);

    const attendeeDisplay = [];
    if (numShluchim > 0)
      attendeeDisplay.push(numShluchim + " " + shluchimDisplay);
    if (numChaperones > 0)
      attendeeDisplay.push(numChaperones + " " + chaperoneDisplay);
    if (numStudents > 0)
      attendeeDisplay.push(numStudents + " " + studentDisplay);

    if (attendeeDisplay.length > 1) {
      const lastIndex = attendeeDisplay.length - 1;
      attendeeDisplay[lastIndex] = "and " + attendeeDisplay[lastIndex];
    }

    return attendeeDisplay.join(", ");
  }, [selectedAttendees]);

  const showStudentDetails = Boolean(studentDetails.length);

  const showShliachChaperoneDetails = Boolean(
    shluchimDetails.length || chaperoneDetails.length
  );

  return (
    <Modal isOpen={show} className="center-modal-container">
      <div className="modal-card card trip-student-checkin-modal">
        <p className="xl-text fw-700 mb-16">Attendee Check-in</p>
        <div>
          <div className="mb-16">
            Confirm that you would like to check in {attendeesForCheckInText()}.
          </div>
          {detailsLoading ? (
            <div className="accent-text">Loading attendee details...</div>
          ) : detailsErrorMessage ? (
            <p className="error-text small-text">{detailsErrorMessage}</p>
          ) : (
            <>
              {showShliachChaperoneDetails && (
                <CheckInShliachChaperoneDetails
                  shluchim={shluchimDetails}
                  chaperones={chaperoneDetails}
                  tracks={tracks}
                />
              )}
              {showStudentDetails && (
                <CheckInStudentDetails
                  studentDetails={studentDetails}
                  tours={tours}
                  tracks={tracks}
                />
              )}
            </>
          )}
        </div>
        {submissionLoading ? (
          <div className="modal-btns-loader">
            <Loader />
          </div>
        ) : (
          <div>
            <div className="checkin-btns">
              <button
                className="btn btn-cancel uppercase-text"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                className="btn btn-accent ml-16 uppercase-text"
                disabled={detailsLoading || detailsErrorMessage}
                onClick={onConfirm}
              >
                Check In
              </button>
            </div>

            {submissionErrorMessage && (
              <span className="error-text checkin-modal-error">
                {submissionErrorMessage}
              </span>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}
export default React.memo(CheckInModal);
