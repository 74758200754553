import React from "react";
import { Tooltip, Typography } from "@material-ui/core";
import {
  formatCurrency,
  formatFullName,
  formatNumber,
} from "../../../../lib/utils";
import ShluchimTableActions from "./ShluchimTableActions";
import { Link } from "react-router";

function ShluchimTableRecord(props) {
  const {
    enrolledView,
    isTravelTrip,
    selected,
    selectionDisabledReason,
    shliachEnrollment: {
      campusName,
      chabadHouseID,
      chabadHouseName,
      chabadHouseOwes,
      isShliachAttending,
      isShluchaAttending,
      numberOfChaperones,
      numberOfShluchim,
      cociOwes,
      numOfStudentRegistered,
      shliachID,
      shliachFirstName,
      shliachLastName,
      shluchaID,
      shluchaFirstName,
      shluchaLastName,
    },
    showSelectCheckbox,
    toggleSelectShliach,
  } = props;

  return (
    <div
      className={`trip-shluchim-table-row${isTravelTrip ? " travel-trip" : ""}`}
    >
      <div className="flex">
        {showSelectCheckbox && (
          <div className="tooltip-container mr-8">
            <div className="flex custom-checkbox-container mr-8">
              <input
                className="custom-checkbox"
                checked={selected}
                disabled={!!selectionDisabledReason}
                id={`enrollment-${chabadHouseID}`}
                onChange={toggleSelectShliach}
                type="checkbox"
              />
              <label
                className="flex-align-center"
                htmlFor={`enrollment-${chabadHouseID}`}
              />
            </div>
            {selectionDisabledReason && (
              <span className="tooltip">{selectionDisabledReason}</span>
            )}
          </div>
        )}
        <Link className="link-text-secondary" to={`/shluchim/${shliachID}`}>
          <div className="flex flex-align-center">
            {!isTravelTrip && (
              <Tooltip
                title={
                  <Typography fontSize={12}>
                    {isShliachAttending ? "Is attending" : "Is not attending"}
                  </Typography>
                }
              >
                <i
                  className="material-icons mr-8"
                  style={{
                    color: isShliachAttending ? "#228b22" : "#e94746",
                  }}
                >
                  {isShliachAttending ? "check" : "close"}
                </i>
              </Tooltip>
            )}
            {formatFullName(shliachFirstName, shliachLastName)}
          </div>
        </Link>
      </div>
      <p>
        <Link className="link-text-secondary" to={`/shluchim/${shluchaID}`}>
          <div className="flex flex-align-center">
            {!isTravelTrip && (
              <Tooltip
                title={
                  <Typography fontSize={12}>
                    {isShluchaAttending ? "Is attending" : "Is not attending"}
                  </Typography>
                }
              >
                <i
                  className="material-icons mr-8"
                  style={{
                    color: isShluchaAttending ? "#228b22" : "#e94746",
                  }}
                >
                  {isShluchaAttending ? "check" : "close"}
                </i>
              </Tooltip>
            )}
            {formatFullName(shluchaFirstName, shluchaLastName)}
          </div>
        </Link>
      </p>
      <p>{chabadHouseName}</p>
      <p>{campusName}</p>
      {!isTravelTrip && (
        <p>
          {enrolledView
            ? `${formatNumber(numberOfShluchim)} + ${formatNumber(
                numberOfChaperones,
              )}`
            : "—"}
        </p>
      )}
      <p>{enrolledView ? formatNumber(numOfStudentRegistered) : "—"}</p>
      {!isTravelTrip && (
        <>
          <p>{enrolledView ? `$${formatCurrency(cociOwes)}` : "—"}</p>
          <p>{enrolledView ? `$${formatCurrency(chabadHouseOwes)}` : "—"}</p>
        </>
      )}
      {enrolledView ? <ShluchimTableActions {...props} /> : <p>—</p>}
    </div>
  );
}
export default React.memo(ShluchimTableRecord);
