import React, { useCallback, useMemo, useState } from "react";
import SelectSearch from "react-select";
import { getTripRegistrationStatusesAndWorkflowSteps } from "../../TripEventLogic";
import { TripRegistrationSurveyStatuses } from "../../TripEventConsts";
import { flattenArray, formatFullName } from "../../../../lib";
import debounce from "lodash.debounce";

function StudentsTableFilters(props) {
  const {
    allEnrolledShluchim,
    allEnrolledShluchimLoading,
    allGenders,
    allStatuses,
    allWorkflowSteps,
    applyFilters,
    tripEvent: { hasParentLetters, isTravelTrip, tours, tracks: allTracks },
  } = props;
  const allTourSchedules = useMemo(() => {
    return tours
      .map((tour) =>
        tour.schedules.map((s) => {
          return { id: s.id, name: `${tour.name} - ${s.name}` };
        })
      )
      .flat();
  }, [tours]);
  const [filters, setFilters] = useState({
    campus: null,
    gender: null,
    keyword: "",
    parentLetterSubmitted: null,
    shliach: null,
    statusesAndWorkflowSteps: [],
    surveyStatus: null,
    tourSchedules: [],
    tracks: [],
  });

  const onFilter = useCallback(
    (filters) => {
      const {
        campus,
        gender,
        keyword,
        parentLetterSubmitted,
        shliach,
        statusesAndWorkflowSteps,
        surveyStatus,
        tourSchedules,
        tracks,
      } = filters;

      const statuses = statusesAndWorkflowSteps.filter(({ value }) =>
        allStatuses.find((s) => s.enumValue === value)
      );
      const workflowSteps = statusesAndWorkflowSteps.filter(({ value }) =>
        allWorkflowSteps.find((s) => s.enumValue === value)
      );

      applyFilters({
        campusId: campus?.value,
        gender: gender?.value,
        keyword,
        shliachId: shliach?.value,
        statuses: statuses.map((s) => s.value),
        tourScheduleIds: tourSchedules.map((t) => t.value),
        trackIds: tracks.map((t) => t.value),
        wasParentLetterSubmitted: parentLetterSubmitted?.value,
        workflowSteps: workflowSteps.map((s) => s.value),
        ...(surveyStatus?.value ===
        TripRegistrationSurveyStatuses.AwaitingSurvey
          ? {
              wasSurveySubmitted: false,
            }
          : surveyStatus?.value ===
            TripRegistrationSurveyStatuses.SubmittedPendingRefund
          ? {
              wasSurveySubmitted: true,
              wasDepositRefunded: false,
            }
          : {}),
      });
    },
    [allStatuses, allWorkflowSteps, applyFilters]
  );

  const onFilterDebounce = useMemo(() => debounce(onFilter, 500), [onFilter]);

  const onChange = useCallback(
    (name, value, debounce) => {
      const updatedFilters = { ...filters, [name]: value };
      setFilters(updatedFilters);

      debounce ? onFilterDebounce(updatedFilters) : onFilter(updatedFilters);
    },
    [filters, onFilter, onFilterDebounce]
  );

  const shluchimOptions = useMemo(
    () =>
      allEnrolledShluchim &&
      allEnrolledShluchim.map((s) => ({
        value: s.shliachID,
        label: formatFullName(s.shliachFirstName, s.shliachLastName),
      })),
    [allEnrolledShluchim]
  );

  const campusOptions = useMemo(
    () =>
      allEnrolledShluchim &&
      flattenArray(allEnrolledShluchim.map((s) => s.enrolledCampuses)).map(
        (c) => ({
          value: c.campusID,
          label: c.campusName,
        })
      ),
    [allEnrolledShluchim]
  );

  const statusAndWorkflowStepOptions = useMemo(
    () =>
      getTripRegistrationStatusesAndWorkflowSteps(
        isTravelTrip,
        allStatuses,
        allWorkflowSteps
      ).map((status) => ({
        value: status.enumValue,
        label: status.displayValue,
      })),
    [allStatuses, allWorkflowSteps, isTravelTrip]
  );

  return (
    <div>
      <div className="trip-students-table-filter-inputs mb-16">
        <div className="search-input">
          <i className="material-icons accent-text-secondary flex flex-align-center ml-8">
            search
          </i>
          <input
            type="text"
            placeholder="Search by student name"
            name="keyword"
            onChange={(event) =>
              onChange(event.target.name, event.target.value, true)
            }
            style={{ padding: "0 8px" }}
            value={filters.keyword}
          />
        </div>
      </div>
      <div
        className="trip-students-table-filter-inputs"
        style={{ flexWrap: "wrap" }}
      >
        <SelectSearch
          className="trip-filter-select mr-16 mb-16"
          isClearable={true}
          isSearchable={true}
          onChange={(val) => onChange("shliach", val)}
          options={shluchimOptions}
          placeholder={
            allEnrolledShluchimLoading
              ? "Loading All Shluchim..."
              : "All Shluchim"
          }
          value={filters.shliach}
        />
        <SelectSearch
          className="trip-filter-select mr-16 mb-16"
          isClearable={true}
          isSearchable={true}
          onChange={(val) => onChange("campus", val)}
          options={campusOptions}
          placeholder={
            allEnrolledShluchimLoading
              ? "Loading All Campuses..."
              : "All Campuses"
          }
          value={filters.campus}
        />
        <SelectSearch
          className="trip-filter-select mr-16 mb-16"
          isClearable={true}
          multi={true}
          isSearchable={true}
          onChange={(val) => onChange("statusesAndWorkflowSteps", val)}
          options={statusAndWorkflowStepOptions}
          placeholder="All Statuses"
          value={filters.statusesAndWorkflowSteps}
        />
        {allTourSchedules.length > 0 && (
          <SelectSearch
            className="trip-filter-select mr-16 mb-16"
            isClearable={true}
            multi={true}
            isSearchable={true}
            onChange={(val) => onChange("tourSchedules", val)}
            options={
              allTourSchedules &&
              allTourSchedules.map((tourSchedule) => ({
                value: tourSchedule.id,
                label: tourSchedule.name,
              }))
            }
            placeholder="All Tour Schedules"
            value={filters.tourSchedules}
          />
        )}
        {allTracks.length > 1 && (
          <SelectSearch
            className="trip-filter-select mr-16 mb-16"
            isClearable={true}
            multi={true}
            isSearchable={true}
            onChange={(val) => onChange("tracks", val)}
            options={
              allTracks &&
              allTracks.map((track) => ({
                value: track.id,
                label: track.trackName,
              }))
            }
            placeholder="All Tracks"
            value={filters.tracks}
          />
        )}
        <SelectSearch
          className="trip-filter-select mr-16 mb-16"
          isClearable={true}
          onChange={(val) => onChange("gender", val)}
          options={
            allGenders &&
            allGenders.map((g) => ({
              value: g.enumValue,
              label: g.displayValue,
            }))
          }
          placeholder="All Genders"
          value={filters.gender}
        />
        {isTravelTrip && (
          <SelectSearch
            className="trip-filter-select mr-16 mb-16"
            isClearable={true}
            onChange={(val) => onChange("surveyStatus", val)}
            options={[
              {
                value: TripRegistrationSurveyStatuses.AwaitingSurvey,
                label: "Awaiting survey",
              },
              {
                value: TripRegistrationSurveyStatuses.SubmittedPendingRefund,
                label: "Survey submitted, pending refund",
              },
            ]}
            placeholder="Survey status"
            value={filters.surveyStatus}
          />
        )}
        {hasParentLetters && (
          <SelectSearch
            className="trip-filter-select mb-16"
            isClearable={true}
            onChange={(val) => onChange("parentLetterSubmitted", val)}
            options={[
              { value: true, label: "Submitted" },
              { value: false, label: "Not submitted" },
            ]}
            placeholder="Parent Letter"
            value={filters.parentLetterSubmitted}
          />
        )}
      </div>
    </div>
  );
}

export default React.memo(StudentsTableFilters);
